import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { GeneralService } from 'src/app/services/general/general.service';

@Injectable({
  providedIn: 'root',
})
export class LoguedGuard implements CanActivate {

  private user: any;
  private userInfo: any;
  
  constructor(
    private router: Router,
    private general: GeneralService
    ) {
  }

  // intentas entrar en registrarte: 
    
    // ¿has iniciado sesión y tienes el PRE registro a true?
        
        // SI: ¿tienes usuario?
                  //SI: compruebo si eres teacher o student
                        // student: te llevo a /student/dashboard
                        // teacher: compruebo

                  //NO: te llevo a que hagas login y no te dejo pasar

        // NO: TE DEJO ENTRAR A /register/

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
    let token = localStorage.getItem('access_token');

    if (typeof token === 'string') {

      await this.getUserInfo();

      if (!this.user) {
        this.router.navigate(['/auth/login'], {
          queryParams: {
            return: state.url,
          },
        });
        return false;
      }
      
      let url: string = "";

      if(this.user.rol == 'student' && this.userInfo.profile_completed == true) {

        url = '/student/dashboard';
        
      }else if (this.user.rol == 'teacher' && this.userInfo.profile_completed == true) {

        if(state.url == "/auth/login") {
          //profesor con sesión iniciada, si vas a login, te mando a dashboard
          url = '/teacher/dashboard';

        }else{

          if (this.userInfo.register_completed == true) {
            // está registrado al completo, lo llevamos a dashboard
            url = '/teacher/dashboard';
          }else{
            // le faltan pasos de registro por completar, dejamos entrar en teacher/register
            return true;
          }
        }
      }


      this.router.navigate([url]);
      return false;
    } else {
      return true;
    }
  }

  async getUserInfo() {
    const response = await this.general.getUserInfo();
    this.userInfo = response.data;
    this.user = this.userInfo.user;
  }

}
