import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { AuthStudentGuard } from './guards/auth-student/auth-student.guard';
import { AuthTeacherGuard } from './guards/auth-teacher/auth-teacher.guard';
import { LoguedGuard } from './guards/logued/logued.guard';

const routes: Routes = [

  // Redirect root
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'search/teacher/:id/:lesson_id',
    loadChildren: () =>
      import('./pages/search/teacher/result/result.module').then(
        (m) => m.ResultModule
      ),
  },
  // Authenticated urls
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      // Class
      {
        path: 'class/:type/:id',
        loadChildren: () =>
          import('./pages/class/class.module').then((m) => m.ClassModule),
      },
      // Messages
      {
        path: 'messages',
        loadChildren: () =>
          import('./pages/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
      },
    ],
  },
  // Only teachers Authenticated Urls
  {
    path: '',
    canActivate: [AuthTeacherGuard],
    children: [
      {
        path: 'teacher/calendar',
        loadChildren: () =>
          import('./pages/teacher/calendar/calendar.module').then(
            (m) => m.CalendarModule
          ),
      },
      {
        path: 'teacher/dashboard',
        loadChildren: () =>
          import('./pages/teacher/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'teacher/settings',

        loadChildren: () =>
          import('./pages/teacher/settings/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'teacher/classes',
        loadChildren: () =>
          import('./pages/teacher/classes/classes.module').then(
            (m) => m.ClassesModule
          ),
      },
      {
        path: 'teacher/statistics',
        loadChildren: () =>
          import('./pages/teacher/statistics/statistics.module').then(
            (m) => m.StatisticsModule
          ),
      },
      {
        path: 'teacher/wallet',
        loadChildren: () =>
          import('./pages/teacher/wallet/wallet.module').then(
            (m) => m.WalletModule
          ),
      },
      {
        path: 'premium',
        loadChildren: () =>
          import('./pages/premium/premium.module').then((m) => m.PremiumModule),
      },
      {
        path: 'teacher/group-classes',
        loadChildren: () =>
          import('./pages/teacher/group-classes/group-classes.module').then((m) => m.GroupClassesModule),
      },

    ],
  },
  // Only students Authenticated Urls

  {
    path: '',
    canActivate: [AuthStudentGuard],
    children: [

      {
        path: 'student/settings',
        loadChildren: () =>
          import('./pages/student/settings/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'student/dashboard',
        loadChildren: () =>
          import('./pages/student/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'student/group-classes',
        loadChildren: () =>
          import('./pages/student/group-classes/group-classes.module').then(
            (m) => m.GroupClassesModule
          ),
      },
    ],
  },
  // No authenticated urls
  {
    path: '',
    children: [
      // Search Teacher
      {
        path: 'search/teacher',
        loadChildren: () =>
          import('./pages/search/teacher/teacher.module').then(
            (m) => m.TeacherModule
          ),
      },
    ],
  },
  // For authentication Urls
  {
    path: 'auth',
    canActivate: [LoguedGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/auth/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'recover-password',
        loadChildren: () =>
          import('./pages/auth/recover-password/recover-password.module').then(
            (m) => m.RecoverPasswordModule
          ),
      },
      {
        path: 'register-teacher',
        loadChildren: () =>
          import('./pages/auth/register-teacher/register-teacher.module').then(
            (m) => m.RegisterTeacherModule
          ),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('./pages/auth/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: 'register-student',
        loadChildren: () =>
          import('./pages/auth/register-student/register-student.module').then(
            (m) => m.RegisterStudentModule
          ),
      },
    ],
  },
  // Need be the last
  
  {
        
    path: '**',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
